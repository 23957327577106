import {message} from 'antd';

import {
  createBranchRequest,
  createServiceRequest,
  deleteBranchRequest,
  deleteServiceRequest,
  fetchAgentsRequest,
  fetchBranchServicesRequest,
  fetchServicesOfBranchRequest,
  getBranchRequest,
  getServiceRequest,
  updateBranchRequest,
  updateServiceRequest,
} from '@/services/api/location';
import {getChangeLoadings, setChangeLoadingsUtility} from '@/utils/form';
import {trackModalView, trackOnlineEditForm} from '@/services/tracker';
import {LOCATION_EDIT_ACTION} from '@/constants/trackerActions';
import {processAPIResponse} from '@/utils/request';
import {fetchUserBranches} from '@/services/api/user';

const initialState = {
  selectedService: {},
  selectedBranch: {},
  list: [],
  changeLoadings: {},
  userBranches: [],
  agentList: [],
  branchDrawer: {
    branch: {},
    visible: false,
    branchId: '',
  },
  ServicesOfBranch: []
}
export default {
  namespace: 'location',

  state: initialState,

  effects: {
    * init({}, {put}) {
      const actions = ['fetch'];
      for (const action of actions) {
        yield put({
          type: action,
        });
      }
    },
    * getUserBranches({payload = {}}, {call, put}) {
      let query = {};
      if (payload.search) {
        query.search = payload.search;
      }
      const {response, data} = yield call(fetchUserBranches, query);
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'fetchedUserBranches',
              payload: data?.data,
            });
          },
          * onError() {
          },
        },
      );
    },
    * getBranch({payload: {branchId}}, {call, put}) {
      const {response, data} = yield call(getBranchRequest, {params: {branchId}});
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'setBranch',
              payload: data?.data,
            });
          },
          * onError() {
          },
        },
      );
    },
    * getService({payload: {serviceId}}, {call, put}) {
      const {response, data} = yield call(getServiceRequest, {params: {serviceId}});
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'setService',
              payload: data?.data,
            });
          },
          * onError() {
          },
        },
      );
    },
    * fetchAgents({}, {call, put}) {
      const { response, data } = yield call(fetchAgentsRequest, {});
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'setAgentList',
              payload: data,
            });
          },
          * onError(error) {
          },
        },
      );
    },
    * fetch({all}, {call, put, select}) {
      const {response, data} = yield call(fetchBranchServicesRequest, {params: {all}});
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'saveBranchServicesList',
              payload: data,
            });
          },
          * onError(error) {
          },
        },
      );
    },
    * fetchServicesOfBranch({payload: {branchId}}, {call, put}) {
      const {response, data} = yield call(fetchServicesOfBranchRequest, {params: {all: 1, branchId}});
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'saveServicesOfBranch',
              payload: data,
            });
          },
          * onError(error) {
          },
        },
      );
    },
    * createBranch({payload}, {call, put, select}) {
      const {response, data} = yield call(createBranchRequest, {payload});
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'toggleBranchDrawer',
            });
            yield put({
              type: 'getUserBranches',
            });
          },
          * onError(error) {
          },
        },
      );
    },
    * deleteBranch({payload: {branchId, cb, intl}}, {call, put}) {
      const {response, data} = yield call(deleteBranchRequest, {params: {branchId}});
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess() {
            yield put({
              type: 'toggleBranchDrawer',
            });
            yield put({
              type: 'getUserBranches',
            });
            yield put({
              type: 'fetch',
            });
            cb && cb(data);
          },
          * onError(error) {
            message.error(
              error?.message || intl.formatMessage({id: 'pages.locations.removeLocationFailed'}),
            );
          },
        },
      );
    },
    * updateBranch({payload: {id, ...body}}, {call, put, select}) {
      yield put({
        type: 'setChangeLoadings',
        payload: getChangeLoadings(body, true),
      });
      const {response, data} = yield call(updateBranchRequest, {
        params: {branchId: id},
        payload: body,
      });
      yield put({
        type: 'setChangeLoadings',
        payload: getChangeLoadings(body, false),
      });
      yield put({
        type: 'getUserBranches',
      });
      trackOnlineEditForm(body, LOCATION_EDIT_ACTION);

      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'fetch',
            });
          },
        },
      );
    },

    * createService({payload}, {call, put, select}) {
      payload.calendar = {...payload?.calendar, appointment_available_from: 48};
      if (!payload.weekdays) {
        payload.calendar.weekdays = [];
      } else {
        payload.calendar.weekdays = payload.weekdays?.map((w) => ({
          ...w,
          day: w.day.toLowerCase(),
        }));
      }
      const {response, data} = yield call(createServiceRequest, {
        payload: {...payload, calendar: {...payload.calendar, process_type: 'instant'}},
      });
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'toggleBranchDrawer',
              payload: {},
            });
            yield put({
              type: 'getUserBranches',
            });
          },
        },
      );
    },
    * deleteService({payload: {serviceId, cb, intl}}, {call, put}) {
      const {response, data} = yield call(deleteServiceRequest, {params: {serviceId}});
      yield processAPIResponse(
        {response, data},
        {
          * onSuccess() {
            yield put({
              type: 'getUserBranches',
            });
            intl &&
            message.success(intl.formatMessage({id: 'pages.locations.removeLocationSuccess'}));
            cb && cb(data);
          },
          * onError(error) {
            message.error(
              error?.message || intl.formatMessage({id: 'pages.locations.removeLocationFailed'}),
            );
          },
        },
      );
    },
    * updateService({payload: {id, ...body}}, {call, put, select}) {
      const {response, data} = yield call(updateServiceRequest, {
        params: {serviceId: id},
        payload: {...body, calendar: {...body.calendar, process_type: 'instant'}},
      });
      yield put({
        type: 'setChangeLoadings',
        payload: getChangeLoadings(body, false),
      });

      trackOnlineEditForm(body, LOCATION_EDIT_ACTION);

      yield processAPIResponse(
        {response, data},
        {
          * onSuccess(data) {
            yield put({
              type: 'fetch',
            });
            if (body.Availability) {
              yield put({
                type: 'updateDrawerLocation',
                payload: {
                  location: data?.data,
                },
              });
            }
          },
        },
      );
    },

  },
  reducers: {
    fetchedUserBranches(state, action) {
      return {
        ...state,
        userBranches: action.payload,
      };
    },
    saveBranchServicesList(state, action) {
      return {
        ...state,
        list: action.payload.data,
      };
    },
    saveServicesOfBranch(state, action) {
      return {
        ...state,
        ServicesOfBranch: action.payload.data,
      };
    },
    setAgentList(state, action) {
      return {
        ...state,
        agentList: action.payload,
      };
    },
    setService(state, action) {
      return {
        ...state,
        selectedService: action.payload,
      };
    },
    setBranch(state, action) {
      return {
        ...state,
        selectedBranch: action.payload,
      };
    },
    setChangeLoadings(state, action) {
      return setChangeLoadingsUtility(state, action);
    },
    toggleBranchDrawer(state, action) {
      const payload = action?.payload;
      const branch = payload?.branch;
      const drawer = state.branchDrawer;

      if (branch) {
        drawer.branchId = branch.id;
        drawer.branch = branch;
        drawer.visible = true;
        trackModalView('location-details');
      } else {
        drawer.visible = false;
      }
      return {
        ...state,
        branchDrawer: drawer,
      };
    },
    reset(){
      return initialState
    }
  },
};
